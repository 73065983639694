import React from 'react'
import { PrismicRichText } from '@prismicio/react'

const Contact = ({ slice }) => {
  return (
    <section className='component-contact mx-auto w-full max-w-screen-2xl py-10 px-6'>
      <div className='relative mx-auto max-w-screen-2xl lg:grid lg:grid-cols-5'>
        {slice.primary.text.richText && (
          <div className='prose prose-lg col-span-2 max-w-screen-2xl lg:pr-10'>
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
        )}
        <div className='col-span-3 py-10 lg:border-l-2 lg:px-10 lg:py-0'>
          <form name='contact' method='POST' action='/success' data-netlify='true' data-netlify-honeypot='surname'>
            <input type='hidden' name='surname' />
            <input type='hidden' name='form-name' value='contact' />
            <div className='mb-4'>
              <label className='mt-4 mb-2 block text-lg' htmlFor='name'>
                What's your name?
              </label>
              <input className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring' type='text' name='name' id='name' required />
            </div>
            <div className='mb-4'>
              <label className='mt-4 mb-2 block text-lg' htmlFor='email'>
                What's your email address?
              </label>
              <input className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring' type='email' name='email' id='email' required />
            </div>
            <div className='mb-4'>
              <label className='mt-4 mb-2 block text-lg' htmlFor='phone'>
                What's your phone number?
              </label>
              <input
                className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 placeholder-gray-500 shadow focus:ring'
                type='tel'
                name='phone'
                id='phone'
                placeholder='Optional'
              />
            </div>
            <div className='mb-4'>
              <label className='mt-4 mb-2 block text-lg' htmlFor='message'>
                How can we help?
              </label>
              <textarea rows='4' className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring' name='message' id='message' required></textarea>
            </div>
            <div className='mb-4'>
              <button type='submit' className='button w-full rounded sm:w-auto'>
                Send your enquiry
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact

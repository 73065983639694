import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BannerWithCaption = ({ slice }) => {
  var position = 'left-0'
  if (slice.slice_label === 'image_right') {
    position = 'right-0'
  }
  return (
    <section className='component-banner_with_caption mx-auto w-full max-w-screen-xl px-4 pb-10'>
      <div className='relative overflow-hidden rounded-md bg-white shadow-md'>
        <div className={`md:absolute md:inset-y-0 md:w-1/2 ${position}`}>
          <GatsbyImage image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} className='h-full w-full overflow-hidden object-cover' />
        </div>
        <div className={`${position === 'left-0' ? 'md:ml-auto md:mr-0' : 'md:mr-auto md:ml-0'} relative flex flex-col items-start justify-center gap-4 p-6 md:w-1/2 md:px-8 md:py-20`}>
          <PrismicRichText field={slice.primary.title.richText} />
          <div className='prose prose-lg max-w-none'>
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
          <PrismicLink field={slice.primary.button_link} className='button mt-6'>
            {slice.primary.button_text}
          </PrismicLink>
        </div>
      </div>
    </section>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired
}

export default BannerWithCaption

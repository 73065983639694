import React from 'react'

const Auction = () => {
  console.log('auction slice loaded')
  return (
    <section className='component-auction mx-auto w-full max-w-screen-2xl py-10 px-6'>
      <link href={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/css/bidjs--full.min.css`} rel='stylesheet' type='text/css' />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.bidjs = {
              config: {
                clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
                googleMapsApiKey: '${process.env.GATSBY_GOOGLE_API_KEY}',
                region: '${process.env.GATSBY_BIDJS_REGION}',
                server: '${process.env.GATSBY_BIDJS_SERVER}',
              },
              options: {
                allowMetaDescriptionChange: true,
                allowTitleChange: true,
                analyticsCode: '${process.env.GATSBY_COMPANY_GA}'
              },
              callback: function (event) {
                switch (event.action) {
                  case 'BIDJS_AUTHENTICATED':
                    if (window.toggleLoggedInState) window.toggleLoggedInState(true)
                  break
                  case 'BIDJS_AUTHENTICATION_REMOVED':
                    if (window.toggleLoggedInState) window.toggleLoggedInState(false)
                  break
                }
              }
            }
          `
        }}
      />
      <div className='bidlogix-app bidjs-app'>
        <div id='bidlogix-modal' />
      </div>
      <div className='bidlogix-app'>
        <div id='bidjs' className='bidjs-app' />
      </div>
    </section>
  )
}

export default React.memo(Auction)

import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Card = ({ fields }) => {
  return (
    <li className='component-card group relative flex flex-grow transform flex-col rounded-md bg-white shadow-md transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg'>
      {fields.image.gatsbyImageData && (
        <div className='transform overflow-hidden rounded-t-md transition duration-500'>
          <GatsbyImage image={fields.image.gatsbyImageData} alt={fields.image.alt || ''} />
        </div>
      )}
      <div className='m-4 flex flex-grow flex-col gap-4'>
        <PrismicRichText field={fields.title.richText} />
        <div className='prose prose-lg max-w-none'>
          <PrismicRichText field={fields.text.richText} />
        </div>
        <PrismicLink className='link mt-auto' field={fields.button_link}>
          {fields.button_text ? fields.button_text : 'See more'}
        </PrismicLink>
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired
}

export default Card

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const socialQuery = graphql`
  {
    prismicConfiguration {
      ...PrismicConfigurationFragment
    }
  }
`

const Social = () => {
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={(data) => {
        if (!data.prismicConfiguration.data.social) return null
        const socialData = data.prismicConfiguration.data.social
        return (
          <div className='mt-4 flex space-x-3 md:order-2'>
            {socialData.map((social, index) => {
              return (
                <a
                  className='transform rounded-full hover:scale-110 focus:outline-none focus:ring focus:ring-purple-500'
                  href={social.social_network_url.url}
                  key={`social-${index}`}
                  target='_blank'
                  rel='noopener noreferrer'>
                  <span className='sr-only'>Visit us on {social.social_network}</span>
                  {social.social_network?.toLowerCase() === 'facebook' && <StaticImage src='../images/facebook.png' alt='Visit us on Facebook' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network?.toLowerCase() === 'instagram' && <StaticImage src='../images/instagram.png' alt='Visit us on Instagram' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network?.toLowerCase() === 'linkedin' && <StaticImage src='../images/linkedin.png' alt='Visit us on LinkedIn' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network?.toLowerCase() === 'twitter' && <StaticImage src='../images/twitter.png' alt='Visit us on twitter' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network?.toLowerCase() === 'github' && <StaticImage src='../images/github.png' alt='Visit us on youtube' placeholder='blurred' layout='fixed' width={32} />}
                </a>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default Social

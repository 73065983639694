import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import PropTypes from 'prop-types'

// Components
import Card from './card'

const CardDeck = ({ slice }) => {
  var grid = 'md:grid-cols-2'
  if (slice.items.length === 3) {
    grid = 'md:grid-cols-3'
  }
  if (slice.items.length === 4) {
    grid = 'md:grid-cols-2 lg:grid-cols-4'
  }
  return (
    <section className='component-card_deck mx-auto w-full max-w-screen-xl py-10 px-5'>
      {slice.primary.deck_title && (
        <div className='prose prose-lg mb-6 max-w-none'>
          <PrismicRichText field={slice.primary.deck_title.richText} />
        </div>
      )}
      <ul className={`grid grid-cols-1 gap-4 ${grid}`}>
        {slice.items.map((card, index) => {
          return <Card fields={card} key={`card-${index}`} />
        })}
      </ul>
    </section>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired
}

export default CardDeck

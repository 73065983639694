import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import EmblaCarousel from './embla/emblaCarousel'

const Image = ({ slice }) => {
  return (
    <section className='component-image_gallery mx-auto w-full max-w-screen-xl py-10 px-5'>
      {slice.primary.gallery_name.text && (
        <div className='prose prose-lg mb-10 max-w-none text-center'>
          <PrismicRichText field={slice.primary.gallery_name.richText} />
        </div>
      )}
      <div className=''>
        {slice.items.length > 1 ? (
          <EmblaCarousel autoplay={true} arrows={true} delayLength={4000} dots={true} loop={true}>
            {slice.items.map((item, index) => {
              return (
                <div className={`relative min-w-full ${'item' + (index === 0 ? ' active' : '')}`} key={`item-${index}`} aria-selected={index === 0 ? 'true' : 'false'}>
                  {item.image.gatsbyImageData && <GatsbyImage className='rounded-xl bg-white' image={item.image.gatsbyImageData} alt={item.image.alt || ''} />}
                  {item.text.richText && (
                    <div className='caption relative z-10 w-full py-3 px-6 text-center'>
                      <PrismicRichText field={item.text.richText} />
                    </div>
                  )}
                </div>
              )
            })}
          </EmblaCarousel>
        ) : (
          <div className='rounded-xl bg-white shadow-md'>
            <GatsbyImage image={slice.items[0].image.gatsbyImageData} alt={slice.items[0].image.alt || ''} />
            {slice.items[0].image.text && (
              <div className='caption relative z-10 w-full py-3 px-6 text-center'>
                <PrismicRichText field={slice.items[0].image.text.richText} />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Image

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useOnClickOutside from './hooks/useOnClickOutside'

const BidJSMenu = ({ admin, isLoggedIn, navStyles, subNavStyles }) => {
  const ref = useRef()
  useOnClickOutside(ref, () => setAccountOpen(false))

  const [isAccountOpen, setAccountOpen] = useState(false)
  const accountToggle = () => setAccountOpen(!isAccountOpen)

  return (
    <li ref={ref} className='relative ml-auto block'>
      <button aria-expanded={isAccountOpen} aria-haspopup='true' className={navStyles} onClick={accountToggle} onKeyDown={accountToggle}>
        <div className='mr-2 rounded-full border border-gray-300 bg-gray-50 p-px'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1}
              d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
        </div>
        My Account
        <div className='text-brand-blue ml-1 mt-1'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
          </svg>
        </div>
      </button>
      <ul className={`${!isAccountOpen ? 'hidden' : 'flex min-w-max flex-col bg-gray-50 lg:absolute lg:right-0 lg:mt-2 lg:rounded lg:bg-white lg:p-3 lg:shadow-md'}`}>
        <li className='' key={`settings${isLoggedIn}`}>
          <a href='../auction/#!/account' className={subNavStyles} onClick={() => accountToggle()}>
            My Settings
          </a>
        </li>
        <li className='' key={`invoice${isLoggedIn}`}>
          <a href='../auction/#!/invoices' className={subNavStyles} onClick={() => accountToggle()}>
            My Invoices
          </a>
        </li>
        <li className='' key={`auctionsArchived${isLoggedIn}`}>
          <a className={subNavStyles} href='../auction/#!/auctionsArchived' onClick={() => accountToggle()}>
            Archived Auctions
          </a>
        </li>
        <li key={`admin${isLoggedIn}`} className={`${admin ? 'block' : 'hidden'}`}>
          <a
            href={`https://${process.env.GATSBY_BIDJS_SERVER}.${process.env.GATSBY_BIDJS_REGION}.${process.env.GATSBY_BIDJS_DOMAIN}/auction-mgt`}
            className={subNavStyles}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => accountToggle()}>
            Admin
          </a>
        </li>
        <li key={`seperator${isLoggedIn}`} className='my-2 border-t' role='separator'></li>
        <li className='' key={`logout${isLoggedIn}`}>
          <a href='../auction/#!/logout' className={`${subNavStyles} items-center justify-center`} onClick={() => accountToggle()}>
            Log Out
            <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 h-5 w-5 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' />
            </svg>
          </a>
        </li>
      </ul>
    </li>
  )
}

BidJSMenu.propTypes = {
  isLoggedIn: PropTypes.bool,
  admin: PropTypes.bool
}

export default BidJSMenu

import React from 'react'
import PropTypes from 'prop-types'

const Map = ({ slice }) => {
  return (
    <section className='component-map mx-auto w-full max-w-screen-xl py-10 px-5'>
      <div className='relative aspect-video'>
        <iframe
          className='absolute inset-0'
          width='100%'
          height='100%'
          title='Google Map of Venue'
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_API_KEY}&q=${slice.primary.map_url.latitude},${slice.primary.map_url.longitude}`}
          allowFullScreen
        />
      </div>
    </section>
  )
}

Map.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Map

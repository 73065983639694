import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/open-sans'
import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => {
      const childProps = { ...props }
      if (!props.href.includes('auction')) {
        delete childProps.activeClassName
        childProps.target = props.target || '_blank'
        childProps.rel = props.rel || 'noopener noreferrer'
      }
      return (
        <a data-location='external' {...childProps}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => {
      if (href.includes('auction')) {
        return (
          <a data-location='auction' href={href} {...props}>
            {props.children}
          </a>
        )
      }
      return <Link to={href} {...props} />
    }}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)

import React, { useEffect, useState } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import jwt_decode from 'jwt-decode'

// Components
import BidJSMenu from './bidjsMenu'
import Dropdown from './dropdown'

const headerQuery = graphql`
  {
    prismicNavigation(lang: { eq: "en-gb" }) {
      ...PrismicNavigationFragment
    }
    prismicConfiguration(lang: { eq: "en-gb" }) {
      ...PrismicConfigurationFragment
    }
  }
`

const Header = (props) => {
  const [isAccountOpen, setAccountOpen] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isToken, setToken] = useState(false)

  const accountToggle = () => setAccountOpen(!isAccountOpen)

  useEffect(() => {
    setLoggedIn(props.isLoggedIn)
    setToken(localStorage.getItem(`bidJSToken${process.env.GATSBY_BIDJS_CLIENT_ID}`))
  }, [props.isLoggedIn])

  var decoded = false
  var admin = false
  var vendor = false
  if (isToken) {
    decoded = jwt_decode(isToken)
    admin = decoded.adminLoginPermitted
    vendor = decoded.publicVendorAdmin
  }

  let navStyles =
    'flex w-full flex-row justify-center lg:justify-between uppercase items-center p-4 lg:py-2 font-medium text-brand-primary transition duration-150 ease-in-out hover:text-gray-600 focus:text-brand-secondary'
  let subNavStyles = 'flex uppercase justify-center lg:justify-between p-4 lg:py-2 font-medium text-gray-700 transition duration-150 ease-in-out hover:text-gray-800 focus:text-gray-900'

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {
        const headerData = data.prismicNavigation.data
        const config = data.prismicConfiguration.data

        return (
          <header role='banner' className='relative z-20 bg-white'>
            <nav className='relative mx-auto flex max-w-screen-2xl items-center gap-10 py-4 px-6'>
              <Link to='/' className='z-20 inline-block'>
                <GatsbyImage className='w-60 object-contain' image={config.company_logo.gatsbyImageData} alt={config.company_display_name} />
              </Link>
              <span className='z-20 ml-auto lg:hidden'>
                <Hamburger color='#014a7f' label='Show menu' rounded toggled={isOpen} toggle={setOpen} />
              </span>
              <div
                className={`${
                  !isOpen ? 'tw-hidden' : 'fixed inset-0 z-10 flex h-screen flex-1 flex-col justify-start bg-black bg-opacity-25 p-2'
                } lg:relative lg:flex lg:h-auto lg:w-full lg:flex-row lg:bg-transparent`}>
                <div className='lg:max-h-none max-h-screen w-full overflow-y-scroll rounded bg-white shadow-lg lg:overflow-visible lg:rounded-none lg:bg-transparent lg:shadow-none'>
                  <ul className='relative w-full flex-row items-center pt-20 pb-4 lg:flex lg:py-0'>
                    {headerData.nav.map((nav, index) => {
                      return (
                        <React.Fragment key={`nav-${index}`}>
                          {nav.items.length > 1 ? (
                            <Dropdown items={nav.items} navStyles={navStyles} subNavStyles={subNavStyles}>
                              {nav.primary.label}
                            </Dropdown>
                          ) : (
                            <li key={`nav-${index}`}>
                              <PrismicLink className={navStyles} field={nav.primary.link}>
                                {nav.primary.label}
                              </PrismicLink>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                    {!isLoggedIn ? (
                      <li className='relative z-10 my-2 px-4 uppercase lg:ml-auto lg:mt-2 lg:mr-0 lg:px-0'>
                        <PrismicLink href='../auction/#!/login' className='button w-full text-center' onClick={() => accountToggle()}>
                          Log In / Register
                        </PrismicLink>
                      </li>
                    ) : (
                      <BidJSMenu navStyles={navStyles} subNavStyles={subNavStyles} isLoggedIn={isLoggedIn} admin={admin} vendor={vendor} />
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Header

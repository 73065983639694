import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Mailchimp = () => {
  const [email, setEmail] = useState()
  const [firstname, setFirstname] = useState()
  const [lastname, setLastname] = useState()
  const [phonenumber, setPhonenumber] = useState()
  const [allsales, setAllsales] = useState()
  const [oilgas, setOilgas] = useState()
  const [energyassets, setEnergyassets] = useState()
  const [foodbeverage, setFoodbeverage] = useState()
  const [printingpackaging, setPrintingpackaging] = useState()
  const [plasticsrubber, setPlasticsrubber] = useState()
  const [metalfabricsteel, setMetalfabricsteel] = useState()
  const [constructionmining, setConstructionmining] = useState()
  const [inlandcoastaloffshore, setInlandcoastaloffshore] = useState()
  const [agricultureforestry, setAgricultureforestry] = useState()
  const [textiles, setTextiles] = useState()
  const [electronics, setElectronics] = useState()
  const [transportationvehicles, setTransportationvehicles] = useState()
  const [officefurnitureit, setOfficefurnitureit] = useState()
  const [formStatus, setFormStatus] = useState(false)
  const [formMessage, setFormMessage] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()

    addToMailchimp(email, {
      FNAME: firstname,
      LNAME: lastname,
      MMERGE3: phonenumber,
      'group[5391][1]': allsales,
      'group[5391][2]': oilgas,
      'group[5391][4]': energyassets,
      'group[5391][8]': foodbeverage,
      'group[5391][16]': printingpackaging,
      'group[5391][32]': plasticsrubber,
      'group[5391][64]': metalfabricsteel,
      'group[5391][128]': constructionmining,
      'group[5391][256]': inlandcoastaloffshore,
      'group[5391][512]': agricultureforestry,
      'group[5391][1024]': textiles,
      'group[5391][2048]': electronics,
      'group[5391][4096]': transportationvehicles,
      'group[5391][8192]': officefurnitureit
    })
      .then(({ msg, result }) => {
        if (result === 'error') {
          setFormStatus('Warning')
          setFormMessage(msg)
        } else {
          setFormStatus(`Success`)
          setFormMessage(msg)
        }
      })
      .catch((error) => {
        setFormStatus('Failure')
        setFormMessage(error)
      })
  }

  const resetForm = () => {
    setFormStatus(false)
    setFormMessage(false)
  }

  let alertColor = 'green'
  if (formStatus === 'Failure') {
    alertColor = 'red'
  }
  if (formStatus === 'Warning') {
    alertColor = 'yellow'
  }

  return (
    <section className='component-mailchimp mx-auto my-6 max-w-screen-2xl bg-white p-4 shadow md:p-10'>
      {!formStatus ? (
        <form onSubmit={handleSubmit}>
          <div className='space-y-4'>
            <div>
              <label className='mb-2 block font-semibold text-gray-700' htmlFor='email'>
                Email Address
              </label>
              <input
                type='email'
                name='email'
                className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring'
                id='email'
                aria-required='true'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label className='mb-2 block font-semibold text-gray-700' htmlFor='firstname'>
                First Name
              </label>
              <input
                type='text'
                name='firstname'
                className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring'
                id='firstname'
                aria-required='true'
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            <div>
              <label className='mb-2 block font-semibold text-gray-700' htmlFor='lastname'>
                Last Name
              </label>
              <input
                type='text'
                name='lastname'
                className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring'
                id='lastname'
                aria-required='true'
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <div>
              <label className='mb-2 block font-semibold text-gray-700' htmlFor='phonenumber'>
                Phone Number
              </label>
              <input
                type='tel'
                className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:ring'
                name='phonenumber'
                id='phonenumber'
                aria-required='true'
                onChange={(e) => setPhonenumber(e.target.value)}
              />
            </div>
            <fieldset>
              <legend className='font-semibold text-gray-700'>Interests</legend>
              <ul className='max-w-lg space-y-4 p-4'>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='1' name='allsales' id='allsales' checked onChange={(e) => setAllsales(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='allsales'>
                      All Sales
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='2' name='oilgas' id='oilgas' onChange={(e) => setOilgas(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='oilgas'>
                      Oil &amp; Gas
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='4' name='energyassets' id='energyassets' onChange={(e) => setEnergyassets(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='energyassets'>
                      Energy Assets
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='8' name='foodbeverage' id='foodbeverage' onChange={(e) => setFoodbeverage(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='foodbeverage'>
                      Food &amp; Beverage
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='16' name='printingpackaging' id='printingpackaging' onChange={(e) => setPrintingpackaging(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='printingpackaging'>
                      Printing &amp; Packaging
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='32' name='plasticsrubber' id='plasticsrubber' onChange={(e) => setPlasticsrubber(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='plasticsrubber'>
                      Plastics &amp; Rubber
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='64' name='metalfabricsteel' id='metalfabricsteel' onChange={(e) => setMetalfabricsteel(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='metalfabricsteel'>
                      Metalworking, Fabrication &amp; Steel-related Industries
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='128' name='constructionmining' id='constructionmining' onChange={(e) => setConstructionmining(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='constructionmining'>
                      Construction &amp; Mining
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='256' name='inlandcoastaloffshore' id='inlandcoastaloffshore' onChange={(e) => setInlandcoastaloffshore(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='inlandcoastaloffshore'>
                      Inland, Coastal &amp; Offshore Boats &amp; Equipment
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='512' name='agricultureforestry' id='agricultureforestry' onChange={(e) => setAgricultureforestry(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='agricultureforestry'>
                      Agriculture &amp; Forestry
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='1024' name='textiles' id='textiles' onChange={(e) => setTextiles(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='textiles'>
                      Textiles
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='2048' name='electronics' id='electronics' onChange={(e) => setElectronics(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='electronics'>
                      Electronics
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='4096' name='transportationvehicles' id='transportationvehicles' onChange={(e) => setTransportationvehicles(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='transportationvehicles'>
                      Transportation &amp; Vehicles
                    </label>
                  </div>
                </li>
                <li className='relative flex items-start'>
                  <div className='flex h-5 items-center'>
                    <input type='checkbox' value='8192' name='officefurnitureit' id='officefurnitureit' onChange={(e) => setOfficefurnitureit(e.target.value)} />
                  </div>
                  <div className='ml-3'>
                    <label className='block text-gray-700' htmlFor='officefurnitureit'>
                      Office Furniture &amp; IT Equipment
                    </label>
                  </div>
                </li>
              </ul>
            </fieldset>
            <div id='mce-responses' className='clear'>
              <div className='response' id='mce-error-response' style={{ display: 'none' }}></div>
              <div className='response' id='mce-success-response' style={{ display: 'none' }}></div>
            </div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
              <input type='text' name='b_4ac35f76079e9361b07fbb29e_74b6f10d0c' tabIndex='-1' />
            </div>
            <p className='prose prose-lg '>
              By clicking subscribe you <a href='/privacy-policy'>agree to our privacy policy</a>
            </p>
            <div className='clear'>
              <button type='submit' name='subscribe' className='button'>
                Subscribe
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className={`rounded-md p-4 bg-${alertColor}-100`}>
          <div className='flex'>
            <div className='mt-1 flex-shrink-0'>
              {formStatus === 'Failure' ? (
                <svg xmlns='http://www.w3.org/2000/svg' className={`h-5 w-5 text-${alertColor}-800`} viewBox='0 0 20 20' fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                    clipRule='evenodd'
                  />
                </svg>
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' className={`h-5 w-5 text-${alertColor}-800`} viewBox='0 0 20 20' fill='currentColor'>
                  <path
                    fillRule='evenodd'
                    d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                    clipRule='evenodd'
                  />
                </svg>
              )}
            </div>
            <div className=' prose-lg ml-3'>
              <h3 className={`font-medium text-${alertColor}-800`}>{formStatus}</h3>
              <div className={`my-2 text-${alertColor}-800`} dangerouslySetInnerHTML={{ __html: formMessage }} />
              {formStatus === 'Failure' && (
                <button type='button' onClick={resetForm} className={`text-${alertColor}-800 underline`}>
                  Try Again
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Mailchimp

import React, { useEffect } from 'react'
import { SliceZone } from '@prismicio/react'

// Slices
import Auction from './auction'
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Hero from './hero'
import Image from './image'
import Mailchimp from './mailchimp'
import Map from './map'
import Products from './products'
import Quote from './quote'
import Text from './text'
import Video from './video'

const components = {
  auction: Auction,
  accordion: Accordion,
  banner_with_caption: BannerWithCaption,
  card_deck: CardDeck,
  contact: Contact,
  counter: Counter,
  hero: Hero,
  image_gallery: Image,
  mailchimp: Mailchimp,
  map: Map,
  products: Products,
  quote: Quote,
  text: Text,
  video: Video
}

const SliceList = ({ slices }) => {
  console.log('slice list loaded')
  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs.min.js`
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return <SliceZone slices={slices} components={components} defaultComponent={() => null} />
}

export default React.memo(SliceList)

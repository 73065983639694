import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

// Components
import ProductCard from '../components/productCard'

const inventoryQuery = graphql`
  {
    allPrismicProduct(limit: 3, sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          ...PrismicProductFragment
        }
      }
    }
  }
`

const Products = ({ slice }) => {
  return (
    <StaticQuery
      query={`${inventoryQuery}`}
      render={(data) => {
        if (!data) return null
        return (
          <div className='bg-texture bg-gray-100 py-10'>
            <section className='component-reserves mx-auto max-w-screen-xl'>
              <div className='flex flex-col flex-wrap items-center justify-between space-y-2 px-4 pt-6 sm:flex-row sm:space-y-0'>
                <PrismicRichText field={slice.primary.title.richText} />
                <Link className='button button-default whitespace-nowrap text-sm hover:bg-black hover:text-white md:text-base' to='/marketplace/'>
                  View more
                </Link>
              </div>
              <div className='relative mx-auto max-w-7xl px-4 py-5 pb-4 sm:px-6 sm:pb-8 md:py-8'>
                <ul className='grid grid-cols-1 gap-5 md:grid-cols-3'>
                  {data.allPrismicProduct.edges.map((product, index) => {
                    const productData = product.node.data
                    return <ProductCard key={`product-${index}`} productData={productData} url={product.node.url} />
                  })}
                </ul>
              </div>
            </section>
          </div>
        )
      }}
    />
  )
}

export default Products

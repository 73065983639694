import React from 'react'
import PropTypes from 'prop-types'

const Video = ({ slice }) => {
  if (slice.primary.video === null) return null
  var padding = {
    paddingBottom: 100 / (slice.primary.video.width / slice.primary.video.height) + '%'
  }
  var embed = slice.primary.video.embed_url
  if (embed !== null && embed.includes('watch')) {
    embed = 'https://www.youtube.com/embed/' + embed.split('v=')[1]
    var ampersandPosition = embed.indexOf('&')
    if (ampersandPosition !== -1) {
      embed = embed.substring(0, ampersandPosition)
    }
  }
  return (
    <section className='component-video mx-auto w-full max-w-screen-xl py-10 px-5'>
      <div className='relative' style={padding}>
        <iframe
          className='absolute inset-0'
          width='100%'
          height='100%'
          title={slice.primary.video.title}
          src={`${embed}?feature=oembed`}
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    </section>
  )
}

Video.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Video

import React, { useState, useRef } from 'react'
import useOnClickOutside from './hooks/useOnClickOutside'
import { PrismicLink } from '@prismicio/react'

const Dropdown = (props) => {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)
  const dropdownToggle = () => setOpen(!isOpen)

  useOnClickOutside(ref, () => setOpen(false))

  return (
    <li ref={ref} className='relative'>
      <button aria-expanded={isOpen} aria-haspopup='true' className={props.navStyles} onClick={dropdownToggle} onKeyDown={dropdownToggle}>
        {props.children}
        <svg xmlns='http://www.w3.org/2000/svg' className='w-5' height='1em'>
          <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor' />
          <path d='M0 0h24v24H0z' fill='none' />
        </svg>
      </button>
      <ul className={`${!isOpen ? 'tw-hidden' : 'flex min-w-max flex-col bg-gray-50 lg:absolute lg:right-0 lg:mt-2 lg:rounded lg:bg-white lg:p-3 lg:shadow-md'}`}>
        {props.items.map((subNav, index) => {
          return (
            <li key={`subNav-${index}`}>
              <PrismicLink className={props.subNavStyles} field={subNav.subnav_link} tabIndex='-1'>
                {subNav.subnav_link_label}
              </PrismicLink>
            </li>
          )
        })}
      </ul>
    </li>
  )
}

export default Dropdown

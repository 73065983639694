import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const Counter = ({ slice }) => {
  return (
    <section className='component-counter mx-auto w-full max-w-screen-xl py-10 px-5'>
      <div className='prose prose-lg max-w-none'>
        <PrismicRichText field={slice.primary.title.richText} />
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        {({ isVisible }) => (
          <div className='flex flex-col justify-evenly py-2 md:flex-row'>
            {slice.items.map((counter, index) => {
              return (
                <div key={`counter-${index}`} className='flex flex-col p-8 text-center leading-none md:p-16'>
                  <CountUp
                    className='text-4xl font-light text-brand-primary md:text-6xl'
                    delay={slice.primary.delay}
                    end={counter.final_number}
                    suffix={counter.suffix || ''}
                    prefix={counter.prefix || ''}
                    separator=','
                    decimal='.'
                    duration={slice.primary.duration}
                  />
                  <div>
                    <PrismicRichText field={counter.title.richText} />
                    <PrismicRichText field={counter.text.richText} />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </VisibilitySensor>
    </section>
  )
}

export default Counter

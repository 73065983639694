import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const productCard = ({ productData, url }) => {
  return (
    <li className='component-card ease relative flex transform flex-col overflow-hidden border border-gray-50 bg-white p-2 shadow-lg transition duration-300 hover:scale-105 hover:shadow-lg'>
      <Link className='flex flex-1 flex-col' to={url}>
        <div className='relative flex-shrink-0'>
          {productData.page_image.gatsbyImageData ? (
            <GatsbyImage className='h-56' image={productData.page_image.gatsbyImageData} alt={productData.page_image.alt || ''} />
          ) : (
            <div className='flex h-56 items-center justify-center bg-gray-200 text-gray-700'>image coming soon</div>
          )}
        </div>
        <div className='m-3 flex flex-1 flex-col bg-white'>
          <div>
            {productData.status ? (
              <span className='inline-flex items-center rounded-md bg-pink-100 px-2.5 py-0.5 text-sm font-medium text-pink-800'>Sold</span>
            ) : (
              <span className='inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800'>Available</span>
            )}
          </div>
          <div className='mb-6 space-y-4'>
            <h3>{productData.page_title.text}</h3>
            <div className='mt-3 mb-6 text-base text-gray-500'>
              {productData.page_text.text?.split(' ').slice(0, 30).join(' ')}
              {productData.page_text.text && <span>&hellip;</span>}
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default productCard

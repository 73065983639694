import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

// Components
import Social from './social'

const footerQuery = graphql`
  {
    prismicNavigation(lang: { eq: "en-gb" }) {
      ...PrismicNavigationFragment
    }
    prismicConfiguration(lang: { eq: "en-gb" }) {
      ...PrismicConfigurationFragment
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={(data) => {
        const footerData = data.prismicNavigation.data
        const config = data.prismicConfiguration.data

        return (
          <footer role='contentinfo' className='mt-auto bg-white text-gray-800'>
            <div className='bg-brand-primary bg-opacity-10 p-8'>
              <div className='prose prose-lg mx-auto text-center lg:prose-xl'>
                <PrismicRichText field={config.mailchimp_title.richText} />
                {config.mailchimp_text && <p>{config.mailchimp_text}</p>}
                <a className='button border-white no-underline hover:text-white' href='/mailing-list/'>
                  Sign up
                </a>
              </div>
            </div>
            <div className='mx-auto grid max-w-screen-2xl grid-cols-1 gap-6 px-6 py-10 md:grid-cols-3'>
              <div>
                <div className='mb-4 text-2xl tracking-tight text-gray-800'>{config.company_display_name}</div>
                <PrismicRichText field={config.company_address.richText} />
                {config.company_phone}
                <Social />
              </div>
              {footerData.body.map((footer, index) => {
                return (
                  <div key={`footer-${index}`}>
                    <div className='mb-4 text-2xl tracking-tight text-gray-800'>{footer.primary.title}</div>
                    <ul>
                      {footer.items.map((footerItem, index) => {
                        return (
                          <li className='my-2' key={`footerItem-${index}`}>
                            <PrismicLink className='py-2 hover:underline' field={footerItem.link}>
                              {footerItem.link_label}
                            </PrismicLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
            <div className='mx-auto max-w-screen-2xl border-t py-4 text-center text-sm'>
              Crafted by{' '}
              <a className='font-semibold text-purple-800' href='https://www.thejuniperstudio.com'>
                The Juniper Studio
              </a>{' '}
              <span className='text-brand-green'>&copy;</span> <span>{new Date().getFullYear()}</span>
            </div>
          </footer>
        )
      }}
    />
  )
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import { PrismicLink, PrismicRichText } from '@prismicio/react'

const Text = ({ slice }) => {
  return (
    <section className='component-text mx-auto w-full max-w-screen-xl py-10 px-5'>
      <div className='prose prose-lg max-w-none'>
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
      <div>
        <PrismicLink className='button mt-6' field={slice.primary.button_link}>
          {slice.primary.button_text}
        </PrismicLink>
      </div>
    </section>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text

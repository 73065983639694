import React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = ({ data }) => {
  const page = data.prismicPage
  const pageData = page.data

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.thumbnails) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  const loggedIn = typeof window === 'undefined' ? false : localStorage.getItem(`bidJSToken${process.env.GATSBY_BIDJS_CLIENT_ID}`) !== null

  return (
    <Layout isLoggedIn={loggedIn} lang={pageData.lang}>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.page_title.text} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      {(pageData.page_image.gatsbyImageData || pageData.page_title.text) && (
        <div className='relative overflow-hidden bg-white'>
          <div className='mx-auto max-w-screen-2xl flex-row md:flex'>
            <div className='hero relative z-10 bg-white p-8 px-6 sm:pb-16 md:pb-20 lg:w-full lg:max-w-3xl lg:py-28 xl:pb-32'>
              <svg className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 lg:block' fill='white' viewBox='0 0 100 100' preserveAspectRatio='none' aria-hidden='true'>
                <polygon points='50,0 100,0 50,100 0,100'></polygon>
              </svg>
              <div className='relative z-20 flex flex-col items-stretch justify-center gap-6 md:max-w-screen-md md:items-start'>
                <div className='prose prose-lg text-gray-500'>
                  <PrismicRichText field={pageData.page_title.richText} />
                  <PrismicRichText field={pageData.page_text.richText} />
                </div>
                <PrismicLink className='button lg:text-xl' field={pageData.page_button_link}>
                  {pageData.page_button_text}
                </PrismicLink>
              </div>
            </div>
            {images && (
              <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
                <GatsbyImage className='h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full' image={images} alt={pageData.page_image.alt || ''} />
              </div>
            )}
          </div>
        </div>
      )}
      <SliceList slices={pageData.body} />
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate)

import React from 'react'
import PropTypes from 'prop-types'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = ({ slice }) => {
  return (
    <section className='component-hero py-10'>
      <div className='relative overflow-hidden bg-white'>
        <div className='mx-auto max-w-screen-2xl flex-row md:flex'>
          <div className='hero relative z-10 bg-white p-8 px-6 sm:pb-16 md:pb-20 lg:w-full lg:max-w-3xl lg:py-28 xl:pb-32'>
            <svg className='absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 lg:block' fill='white' viewBox='0 0 100 100' preserveAspectRatio='none' aria-hidden='true'>
              <polygon points='50,0 100,0 50,100 0,100'></polygon>
            </svg>
            <div className='relative z-20 flex flex-col items-stretch justify-center gap-6 md:max-w-screen-md md:items-start'>
              <PrismicRichText field={slice.primary.title.richText} />
              <PrismicRichText field={slice.primary.text.richText} />
              <PrismicLink className='button' field={slice.primary.button_link}>
                {slice.primary.button_text}
              </PrismicLink>
            </div>
          </div>
          {slice.primary.image.gatsbyImageData && (
            <div className='lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2'>
              <GatsbyImage className='h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full' image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Hero
